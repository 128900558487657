.round-robin__table {
	width: 100vw;
	border: solid black;
	border-top-width: 2px;
	border-right-width: 0;
	border-bottom-width: 0;
	border-left-width: 2px;
	border-collapse: collapse;
	text-align: center;
	table-layout: fixed;
	margin: 0 0 30px 0;
	page-break-inside: avoid;

	.round-robin__header {
		color: var(--palette-live-grey-0);
		font-size: var(--font-size-17);
		font-weight: var(--font-weight-medium);
	}

	.round-robin__dubble-name {
		display: flex;
		align-items: center;

		& span {
			font-size: var(--font-size-14);
			color: var(--palette-live-grey-0);
			font-weight: var(--font-weight-regular);
			margin: 0 14px 0 0;
		}

		& div {
			display: flex;
			flex-direction: column;
			text-align: left;

			& span:first-child {
				margin: 0 0 10px 0;
			}

			& span {
				color: var(--palette-live-grey-0);
				font-size: var(--font-size-17);
				margin: 0 0 0 0;
				font-weight: var(--font-weight-regular);
			}
		}
	}

	.round-robin__single-name {
		display: flex;
		align-items: center;
		font-size: var(--font-size-17);
		color: var(--palette-live-grey-0);
		font-weight: var(--font-weight-regular);
		margin: 0 0 0 0;

		& span {
			font-size: var(--font-size-14);
			color: var(--palette-live-grey-0);
			font-weight: var(--font-weight-regular);
			margin: 0 14px 0 0;
		}
	}

	.round-robin__black-block {
		background-color: var(--palette-live-grey-2);
	}
}

thead th:first-child, tbody td:first-child {
	text-align: center;
}

td, th {
	border: 2px solid black;
	border-left-width: 0;
	padding: 14px;
	line-height: 1.2;
	max-height: 50px;
	break-inside: avoid;
}