.table-matches {
	width: 100%;
	min-width: max-content;
	display: flex;
	flex-direction: column;
}

.match-group__header {
	flex: 1;
	min-height: 48px;
	max-height: 48px;
	display: flex;
	align-items: center;
	margin: 0 15px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	border-top: 1px solid var(--palette-live-grey-7);
	border-left: 1px solid var(--palette-live-grey-7);
	border-right: 1px solid var(--palette-live-grey-7);
	box-sizing: border-box;
	padding: 0 13px;

	.match-group__title {
		color: var(--palette-live-grey-0);
		font-size: var(--font-size-20);
		font-weight: 500;
		margin: 0;
	}
}

.match-table__container {
	display: table;
	margin: 0 15px 35px 15px;
	box-sizing: border-box;
	border: 1px solid var(--palette-live-grey-7);
}

.match-table__row {
	display: table-row;
	width: 100%;
	white-space: nowrap;
	box-sizing: border-box;
}

.match-table__cell {
  display: table-cell;
  border-bottom: 1px solid var(--palette-live-grey-7);
  color: var(--palette-live-grey-0);
  font-size: var(--font-size-17);
  font-weight: 400;
  vertical-align: middle;
  position: relative;
  text-align: left;
  min-width: 35px;
  width: 100%;
  box-sizing: border-box;
	height: 100%;
}

.match-table__cell:first-child {
	border-right: 1px solid var(--palette-live-grey-6);
}

.match-table__row:last-child .match-table__cell {
	border-bottom: none;
}

.match-table__cell-names {
  padding: 5px 15px 5px 10px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	min-height: 76px;

	p {
		min-height: 22px;
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		box-sizing: border-box;
	}
}

.match-table__cell-names-doubles {
	padding: 5px 15px 5px 10px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: 115px;

	.match-table__cell-doubles-container {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-sizing: border-box;
		height: 56px;
		box-sizing: border-box;

		.match-table__cell-doubles-player {
			display: block;
			min-height: 22px;
		}

		.empty-participant:first-child {
			margin-bottom: 16px;
		}
	}
}

.cell-order{
	width: 26px;
}

.match-table__cell-order{
	width: 100%;
	text-align: center;
	margin: 0 0 5px 0;
}

.match-table__cell-ball{
	text-align: center;
}

.match-table__cell-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 76px;

  &.doubles {
    height: 115px;
  }
}

.match-table__cell-sets{
  padding-right: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.match-table__cell-sets-score {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.match-table__cell-sets-score:last-child {
	border: 0;
	padding: 0;
}
.match-table__cell-sets-score > p {
	margin: 0 5px 0 0;
	cursor: pointer;
	min-width: 37px;
}
.match-table__cell-sets-score > p:last-child {
	margin: 0;
}
.match-table__cell-sets-score > p > span {
	color: var(--palette-live-blue);
}

.match-group__notable{
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	max-width: 100%;
	display: flex;
	align-items: center;
	height: 48px;
	padding: 0 15px;
	box-sizing: border-box;
	border: 1px solid var(--palette-live-grey-7);
	margin: 15px 15px 35px 15px;

	.match-group__notable-group-name{
		font-weight: 500;
		font-size: var(--font-size-20);
		margin: 0;
	}
}