.bracket {
	display: flex;
	flex-direction: row;
	margin: 0 16px 0 16px;

	.round {
		margin: 0 5px 10px 0;

		&:last-child {
			margin: 0 0 0 0;
		}

		.round-details {
			text-align: center;
			border: 1px solid #C9CDD1;
			padding: 10px 0 10px 0;
			margin: 0 0 10px 0;
			background-color: white;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			&--round-left {
				border-top-left-radius: 15px;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}

			&--round-right {
				border-top-left-radius: 0;
				border-top-right-radius: 15px;
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}

			.round-details-type {
				font-size: var(--font-size-21);
				font-weight: 600;
				position: absolute;
				left: 10px;
			}

			.round-details-number {
				font-size: 14px;
				margin: 0;
			}

			.round-details-number--big {
				font-size: 14px;
				font-weight: 600;
			}
		}

		.round-container {
			display: flex;
			align-items: center;
			margin: 0 0 10px 0;

			.round-wrapper {
				width: -webkit-fill-available;

				.matchup-container {
					display: flex;
					align-items: center;
					margin: 0 0 10px 0;

					&:last-child {
						margin: 0 0 0 0;
					}

					.matchup-wrapper {
						flex: 1;
						display: flex;
						flex-direction: column;
						justify-content: space-around;

						.matchup {
							margin: 0 0 10px 0;
							display: flex;
							flex-direction: row;
							align-items: center;

							&:last-child {
								margin: 0 0 0 0;
							}

							.team-container {
								width: -webkit-fill-available;

								.team {
									height: 53px;
									border: 1px solid #C9CDD1;
									margin: 0 0 5px 0;
									padding: 0 0 0 5px;
									background-color: white;
									display: flex;
									justify-content: space-between;

									&:last-child {
										margin: 0 0 0 0;
									}

									.team-wrapper {
										display: flex;
										flex: 1;

										.team-number-wrapper {
											margin: 0 0 0 0;
											border: solid #E6EBEE;
											border-top-width: 0;
											border-right-width: 1px;
											border-bottom-width: 0;
											border-left-width: 0;
											display: flex;
											align-items: center;
											padding: 0 5px 0 0;

											.team-number {
												margin: 0;
												font-size: 12px;
												color: #A1A6AB;
											}
										}

										.team-name-wrapper {
											display: flex;
											flex-direction: column;
											justify-content: center;
											border: solid #E6EBEE;
											border-top-width: 0;
											border-right-width: 0;
											border-bottom-width: 1px;
											border-left-width: 0;
											flex: 1;

											&:last-child {
												border-bottom-width: 0;
											}

											.team-name__sets-wrapper {
												display: flex;
												cursor: pointer;
												height: 100%;
											}

											.team-name-title {
												margin: 0 0 0 0;
												font-size: 13px;

												&:first-child {
													margin: 7px 0 7px 5px;
												}

												&:last-child {
													margin: 0 0 7px 5px;
												}
											}

											.team-score-container--double {
												margin: 0 7px 0 7px;
												display: flex;
												flex-direction: column;
												justify-content: center;
												position: relative;

												.team-score-container-tiebreak--double {
													position: absolute;
													color: var(--palette-live-blue);
													font-family: "Sawarabi Gothic";
													margin: 0;
													font-size: var(--font-size-11);
													top: -10px;
													right: -4px;
												}

												.team-score-number--double {
													font-size: 13px;
													margin: 0 0 7px 0;
													color: #0085F4;

													&:last-child {
														margin: 0 0 0 0;
													}
												}
											}
										}
									}

									.team-score-wrapper {
										display: flex;
										align-items: center;
										cursor: pointer;
										margin: 0 15px 0 0;

										.team-score-container {
											margin: 0 7px 0 0;

											&:last-child {
												margin: 0 0 0 0;
											}

											.team-score__wrapper {
												position: relative;

												.team-score-container-tiebreak {
													position: absolute;
													color: var(--palette-live-blue);
													font-family: "Sawarabi Gothic";
													margin: 0;
													font-size: var(--font-size-11);
													top: -6px;
													right: -6px;
												}
											}

											.team-score-number {
												font-size: 13px;
												margin: 0 0 7px 0;
												color: #0085F4;

												&:last-child {
													margin: 0 0 0 0;
												}
											}
										}
									}
								}

								.result {
									background-color: white;
									display: flex;
									justify-content: space-between;

									.result-wrapper {
										flex: 1;

										.result-name-wrapper {
											display: flex;
											justify-content: space-between;
											border: 1px solid #C9CDD1;

											.result-name-title {
												font-size: 13px;
												margin: 0 0 0 5px;
												flex: 1;
												display: flex;
												align-items: center;
											}

											.result-name-number {
												width: 25px;
												height: 25px;
												background-color: #2D2F30;
												display: flex;
												color: white;
												font-size: 13px;
												align-items: center;
												justify-content: center;
											}
										}
									}
								}

								.result--double {
									display: flex;
									flex-direction: column;
									border: 1px solid #C9CDD1;
									background-color: white;
									display: flex;
									justify-content: space-between;

									.result-name-wrapper--double {
										border: solid #E6EBEE;
										border-top-width: 0;
										border-right-width: 0;
										border-bottom-width: 1px;
										border-left-width: 0;
										display: flex;
										justify-content: space-between;
										align-items: center;

										&:last-child {
											border-bottom-width: 0;
										}

										.result-name-container--double {

											.result-name-title--double-first {
												font-size: 13px;
												margin: 7px 0 7px 5px;
												flex: 1;
												display: flex;
												align-items: center;
											}

											.result-name-title--double-second {
												font-size: 13px;
												margin: 0 0 7px 5px;
												flex: 1;
												display: flex;
												align-items: center;
											}
										}

										.result-name-number--double {
											width: 25px;
											height: 51px;
											background-color: #2D2F30;
											display: flex;
											color: white;
											font-size: 13px;
											align-items: center;
											justify-content: center;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.matchup-line {
	width: 7px;
	border: 1px solid #C9CDD1;
	border-top-width: 1px;
	border-right-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 0;
}

.matchup-line-one {
	width: 10px;
	height: 1px;
	background: #C9CDD1;
	margin: 0 0 0 -5px;
}

.empty-player {
	display: block;
}