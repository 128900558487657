.search__wrapper {
	margin: 0 16px 16px 16px;
}

.players {
	overflow-x: hidden;
	margin: 0 16px 0 16px;
	flex: 1;

	.arrow-link {
		padding: 12px;
	}

	.players__wrapper {
		.players__number {
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-20);
			margin: 0 16px 0 0;
			font-weight: var(--font-weight-regular);
		}

		.players__name {
			color: var(--palette-live-grey-0);
			font-size: var(--font-size-18);
			margin: 0 0 0 0;
			font-weight: var(--font-weight-regular);
		}
	}
}