.olympic-plus__grid-wrapper {
	page-break-inside: avoid;

	.olympic-plus__grid-group {
		color: var(--palette-live-grey-0);
		font-size: var(--font-size-21);
		font-weight: var(--font-weight-medium);
		margin: 10px 0 10px 0;
	}

	.olympic-plus__grid-table {
		width: 100vw;
		border: solid black;
		border-top-width: 2px;
		border-right-width: 0;
		border-bottom-width: 0;
		border-left-width: 2px;
		border-collapse: collapse;
		text-align: center;
		table-layout: fixed;
		margin: 0 0 30px 0;
	}
}

.olympicplus-grid__match-sides--print {

	.olympicplus-grid__match-participant--print {
		font-size: var(--font-size-17);
		color: var(--palette-live-grey-0);
		font-weight: var(--font-weight-regular);
		margin: 0 14px 0 14px;
		text-align: left;

		&:last-child {
			margin: 10px 14px 0 14px;
		}
	}

	.olympicplus-grid__match-dobles--print {
		margin: 10px 0 10px 0;

		&:last-child {
			margin: 0 0 10px 0;
		}

		.olympicplus-grid__match-double-player--print {
			font-size: var(--font-size-17);
			color: var(--palette-live-grey-0);
			font-weight: var(--font-weight-regular);
			margin: 0 14px 0 14px;
			text-align: left;
		}
	}
}

table {
	height: 100%;
	border-collapse: collapse !important;
	border-spacing: 0 !important;
}

td, th {
	border: 2px solid black;
	border-left-width: 0;
	padding: 14px;
	line-height: 1.2;
	max-height: 25px;
	break-inside: avoid;
}

.olympic-td__wrapper {
	padding: 0;

	.olympic-td__element {
		border: solid black;
		border-top-width: 0;
		border-right-width: 0;
		border-bottom-width: 2px;
		border-left-width: 0;
		height: 17px;

		&:first-child {
			border-top-width: 0;
			border-right-width: 2px;
			border-bottom-width: 2px;
			border-left-width: 0;
		}

		&--width {
			width: 100%;
		}

		&--bottom-border {
			border-top-width: 0;
			border-right-width: 0;
			border-bottom-width: 0 !important;
			border-left-width: 0;
		}

		&--background {
			background-color: var(--palette-live-grey-16);
		}
	}
}