.no-play-print__grid-wrapper {
	page-break-inside: avoid;
	display: flex;
	flex-direction: column;
	margin: 0 0 30px 0;

	.no-play-print__grid-group {
		color: var(--palette-live-grey-0);
		font-size: var(--font-size-21);
		font-weight: var(--font-weight-medium);
		margin: 10px 0 10px 0;
	}

	.no-play-print-grid__table {
		display: block;
		text-align: center;
		background-color: var(--palette-live-bg-0-white);

		thead {
			border: 2px solid black;
		}

		tbody {
			border: 2px solid black;

			.no-play-print-grid__row-table {


				.no-play-print-grid__match-player-wrapper {
					width: 100vw;
					text-align: left;
				}

				.no-play-print-grid__match-double-player-wrapper {
					text-align: left;
					margin: 0 0 14px 0;

					&:last-child {
						margin: 0 0 0 0;
					}
				}

				.no-play-print-grid__index {

				}
			}
		}
	}
}

table {
	height: 100%;
	border-collapse: collapse !important;
	border-spacing: 0 !important;
}

td, th {
	border: 2px solid black;
	border-left-width: 0;
	padding: 14px;
	line-height: 1.2;
	max-height: 25px;
	break-inside: avoid;
}