.popup {
	display: flex;
	background-color: rgba(0, 0, 0, 0.29);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	justify-content: center;
	align-items: center;
	z-index: 9999;

	&__wrapper {
		flex: 1;
		background-color: var(--palette-live-bg-0-white);
		border-radius: 11px;
		padding: 20px 0 20px 0;
		margin: 0 10px;
		overflow: auto;
		max-height: calc(100vh - 80px);
		max-width: 	400px;

		.popup__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 0 16px 20px 16px;

			.popup__title {
				font-size: var(--font-size-20);
				color: var(--palette-live-grey-0);
				margin: 0;
				font-weight: var(--font-weight-medium);
				text-align: center;
			}

			.popup__arrow-icon, .popup__close-icon {
				padding: 5px;
				cursor: pointer;
			}

			.popup__close-icon {
				fill: var(--palette-live-grey-0);
			}
		}

		.popup__body {
			overflow-y: auto;
			max-height: calc(100vh - 140px);
		}
	}
}